<template>
    <zingchart :data="HorizontalBarchartLocationData" ref="chart" :series="chartDataSeries"></zingchart>
</template>
<script>
import 'zingchart/zingchart-es6.min.js';
import zingchartVue from 'zingchart-vue';
export default {
     components: {
        zingchart: zingchartVue,
    },
    computed: {
            customerNumber () {
                return this.$store.state.userDetails.subscriptionId;
            },
            surveyYear(){
                return this.$store.getters.surveyYear;
            },
    },
     data(){
         return{
              HorizontalBarchartLocationData:{
                 type: 'hbar',
                 plot: {
                    stacked: true
                },
                 legend: {},
                 tooltip: {
                        text: 'offset: %offset-values<br> value: %v',
                        textAlign: 'left'
                    },
                    scaleX: {
                        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu']
                    },
                    scaleY: {
                        minValue: 0,
                        maxValue: 16,
                        step: 4.5,
                        decimals: 1
                    },
                     title: {
                            fontColor: "#8e99a9",
                            text: 'Compensation Distribution Location-Year Wise',
                            align: "left",
                            offsetX: 10,
                            fontFamily: "Open Sans",
                            fontSize: 25
                        },
                    series: [{
                             values: [5.0, 3.0, 5.5, 2.0, 2.5],
                            offsetValues: [2.0, 3.0, 1, 2.0, 2.5],
                            backgroundColor: '#FF6600',
                            valueBox: {
                                placement: 'bottom',
                                rules: [{
                                rule: '%offset-values == 0',
                                visible: false
                                }]
                            }
                            },
                            {
                            values: [5.0, 8.0, 9.0, 4.0, 3.5],
                            offsetValues: [1.0, 3.0, 0, 2.0, 2.5],
                            backgroundColor: '#DC143C',
                            valueBox: {
                        
                            }
                        }],
             },
         }
     },
      methods:{
         loadReportData(){
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerDashboard/CompDistPerLocation`,
                params:{
                    CustomerNumber:this.customerNumber,
                }
            }).then((response)=>{
                     if(JSON.parse(response.data.status) == true){
                        // console.log('aaaaaaaaaaaaaaaaaaa')
                        // console.log(response.data);
                         let series = [];
                         let years = [];
                         years = Array.from(new Set(response.data.analysisData.map((item) => item.year)))
                         let sum = response.data.analysisData.map(d=>d.compensation)
                            .reduce((sum, current) => sum + current, 0)
                        let locations = Array.from(new Set(response.data.analysisData.map((item) => item.location)))
                        // console.log(sum)

                         this.$refs.chart.data = {
                                type: 'hbar',
                                plot: {
                                    stacked: true
                                },
                                legend: {},
                                tooltip: {
                                        text: 'offset: %offset-values<br> value: %v',
                                        textAlign: 'left'
                                    },
                                     scaleX: {
                                        labels: years
                                    },
                                    scaleY: {
                                        minValue: 0,
                                        maxValue: sum,
                                        step: sum/10,
                                        decimals: 1
                                    },
                                    title: {
                                        fontColor: "#8e99a9",
                                        text: 'Compensation Distribution Location-Year Wise',
                                        align: "left",
                                        offsetX: 10,
                                        fontFamily: "Open Sans",
                                        fontSize: 25
                                    },
                         }
                       
                         locations.forEach((loc)=>{
                             let w = [];
                             years.forEach((year)=>{
                                 var locationTitlesCompensation =
                                    response.data.analysisData.filter(d=>d.year == year && d.location == loc)
                                    .reduce((sum, current) => sum + current.compensation, 0);
                                w.push(locationTitlesCompensation)
                             });
                              series.push({
                                    values: w,
                                    backgroundColor: '#'+(0x1000000+Math.random()*0xFF6600).toString(16).substr(1,6), 
                                     text:loc,
                                    offsetValues: [1.0],
                                    
                            })
                         });
                         this.$refs.chart.series = series; 

                       
                     }
            });
         }
     },
     mounted(){
          this.loadReportData();
      }
}
</script>

<style>

</style>