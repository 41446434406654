<template>
    <zingchart :data="HeadCountSegAnalysisLocationData" ref="chart" :series="chartDataSeries"></zingchart>
</template>

<script>
import 'zingchart/zingchart-es6.min.js';
import zingchartVue from 'zingchart-vue';
export default {
    components: {
        zingchart: zingchartVue,
    },
    computed: {
            customerNumber () {
                return this.$store.state.userDetails.subscriptionId;
            },
            surveyYear(){
                return this.$store.getters.surveyYear;
            },
    },
    data(){
        return{
             HeadCountSegAnalysisLocationData: {
                  type: 'pie',
                   plot: {
                        borderColor: "#2B313B",
                        borderWidth: 5,
                        // slice: 90,
                        valueBox: {
                            placement: 'out',
                            text: '%t\n%npv%',
                            fontFamily: "Open Sans"
                        },
                     tooltip: {
                            fontSize: '18',
                            fontFamily: "Open Sans",
                            padding: "5 10",
                            text: "%npv%"
                        },
                    animation: {
                            effect: 2,
                            method: 5,
                            speed: 900,
                            sequence: 1,
                            delay: 3000
                        }
                    },
                    source: {
                        text: 'gs.statcounter.com',
                        fontColor: "#8e99a9",
                        fontFamily: "Open Sans"
                    },
                    title: {
                            fontColor: "#8e99a9",
                            text: 'Headcount Segment Analysis - Location - Year wise',
                            align: "left",
                            offsetX: 10,
                            fontFamily: "Open Sans",
                            fontSize: 25
                        },
                    //  subtitle: {
                    //         offsetX: 10,
                    //         offsetY: 10,
                    //         fontColor: "#8e99a9",
                    //         fontFamily: "Open Sans",
                    //         fontSize: "16",
                    //         text: 'May 2016',
                    //         align: "left"
                    //     },
                    plotarea: {
                            margin: "20 0 0 0"
                        },
                    series: [{
                            values: [11.38],
                            text: "Enabler Job",
                            backgroundColor: '#50ADF5',
                            },
                            {
                            values: [56.94],
                            text: "Core Jobs",
                            backgroundColor: '#FF7965',
                            //detached: true
                            },
                            {
                            values: [31.68],
                            text: 'Support Jobs',
                            backgroundColor: '#FFCB45',
                            //detached: true
                            },
                        ]
                },
        }
    },
     methods:{
         loadReportData(){
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerDashboard/HeadCountPerLocation`,
                params:{
                    CustomerNumber:this.customerNumber,
                    SurveyYear: this.surveyYear
                }
            }).then((response)=>{
                     if(JSON.parse(response.data.status) == true){
                        // console.log('aaaaaaaaaaaaaaaaaaa')
                        // console.log(response.data);
                        let totalHeadcount = response.data.analysisData.locations.length;
                        let locations = Array.from(new Set(response.data.analysisData.locations.map((item) => item.location)))
                        let series = [];

                      
                        locations.forEach(location => {
                            let positoinsInLocation = 
                            response.data.analysisData.locations.filter(d=>d.location == location);
                            let locationPercent = positoinsInLocation.length /  totalHeadcount
                            series.push({
                                values: [locationPercent],
                                text: location,
                               backgroundColor: '#'+(0x1000000+Math.random()*0xFF6600).toString(16).substr(1,6), 
                            });
                        });
                        console.log(series);
                        this.$refs.chart.series = series;

                     }
            });
         }
     },
    mounted(){
          this.loadReportData();
      }
}
</script>

<style>

</style>