<template>
    <div>
        <b-container fluid >
            <b-row class="my-2">
                <b-col class="sm-2"><h2>Customer Dashboard</h2></b-col>
                <b-col class="sm-9"></b-col>
                <b-col class="sm-1">
                    <div>
                        <b-dropdown split
                            split-variant="outline-primary"
                            variant="primary"
                            text="Add Widget"
                            class="m-2">
                                <b-dropdown-item href="#">Survey Parameters</b-dropdown-item>
                                <b-dropdown-item href="#">Job Family Mapping</b-dropdown-item>
                                <b-dropdown-item href="#">Compensatopn Distribution Dpt-year wise</b-dropdown-item>
                                <b-dropdown-item href="#">Compensatopn Distribution Location-year wise</b-dropdown-item>
                                <b-dropdown-item href="#">Headcount Segment Analysis Dpt-year wise</b-dropdown-item>
                                <b-dropdown-item href="#">Compensatopn Distribution Location-year wise</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-col>
            </b-row>
        </b-container>
         <b-container fluid >
             <b-row class="my-1">
                  <b-col class="sm-2">
                    <form ref="form" @submit.stop.prevent="SaveAnalysis">
                         <b-row class="my-1"> 
                            <b-col sm="2">
                                <label for="txtNatureOfJob">
                                    Survey Year :
                                </label>
                            </b-col>
                            <b-col sm="4">
                               {{surveyYear}}
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="5">
                                <label for="txtinflation">
                                    Inflation :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtinflation" 
                                    v-model="analysisForm.inflation"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                         <b-row> 
                            <b-col sm="5">
                                <label for="txtgdpAndYoyGrowth">
                                    Gdp And Yoy Growth :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtgdpAndYoyGrowth" 
                                    v-model="analysisForm.gdpAndYoyGrowth"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="5">
                                <label for="txtcostOfLiving">
                                    Cost of living and YoY change :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtcostOfLiving" 
                                     v-model="analysisForm.costOfLiving"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col sm="5">
                                <label for="txtunEmployment">
                                    UnEmployment :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtunEmployment" 
                                    v-model="analysisForm.unEmployment"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="5">
                                <label for="txtinterestRates">
                                    Interest Rates :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtinterestRates" 
                                    v-model="analysisForm.interestRates"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col sm="5">
                                <label for="txtfxUSDoverEGP">
                                    F.X USD Over EGP :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtfxUSDoverEGP" 
                                    v-model="analysisForm.fxUSDoverEGP"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col sm="5">
                                <label for="txtpopulationMin">
                                    Population Min and Yoy Growth :
                                </label>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input 
                                    id="txtpopulationMin" 
                                    v-model="analysisForm.populationMin"
                                    type="number">
                                </b-form-input>
                            </b-col>
                            <b-col sm="1">
                                %
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col sm="12">
                                <center>
                                    <b-button  type="submit" variant="primary"  block > Update </b-button>
                                </center>
                            </b-col>
                        </b-row>
                    </form>   
                  </b-col>
                  <b-col class="sm-8">
                    <jobFamilyChart />
                  </b-col>
             </b-row>
         </b-container>
          <b-container fluid >
              <b-row class="my-1">
                  <b-col class="sm-6">
                      <CompensationDistDeptChart />
                  </b-col>
                  <b-col class="sm-6">
                    <CompensationDistLocationChart />
                  </b-col>
              </b-row>
             <b-row class="my-1">
                  <b-col class="sm-6">
                      <headCountSegDeptChart />
                  </b-col>
                  <b-col class="sm-6">
                     <headCountSegLocationChart />
                  </b-col>
              </b-row>
          </b-container>
           <b-modal id="DisclaimerDialog" title="Disclaimer" hide-footer hide-backdrop>
                <div class="d-block text-center">
                    <h3>First-Login Disclaimer</h3>
                </div>
                <h4>
                    Any information used here is 100% secured and used for analysis purposes only
                </h4>
                <b-button class="mt-3" variant="outline-primary" block @click="DisclaimerAgreed">I Agree</b-button>
            </b-modal>

    </div>
</template>

<script>
import jobFamilyChart from './CustomerDashboardComponents/jobFamilyChartComponent'
import headCountSegDeptChart from './CustomerDashboardComponents/HeadCountSegDepComponent'
import headCountSegLocationChart from './CustomerDashboardComponents/HeadCountSegLocationComponet'
import CompensationDistDeptChart from './CustomerDashboardComponents/CompensationDistDeptComponent'
import CompensationDistLocationChart from './CustomerDashboardComponents/CompensationDistLocationComponent'
export default {
    components: {
        'jobFamilyChart':jobFamilyChart,
        'headCountSegDeptChart':headCountSegDeptChart,
        'headCountSegLocationChart':headCountSegLocationChart,
        'CompensationDistDeptChart':CompensationDistDeptChart,
        'CompensationDistLocationChart':CompensationDistLocationChart
    },
    computed: {
        surveyYear(){
            return this.$store.state.userDetails.surveyYear;
        },
        customerNumber () {
             return this.$store.state.userDetails.subscriptionId;
        }
    },
     data(){
         return{
              analysisForm:{
                id:0,
                inflation:null,
                gdpAndYoyGrowth:null,
                costOfLiving:null,
                unEmployment:null,
                interestRates:null,
                fxUSDoverEGP:null,
                populationMin:null,
            },
         }

     },
     methods:{
         DisclaimerAgreed(){
            //  console.log('userId')
            //  console.log(this.$store.getters['userId'] )
              this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/Account/DisclaimerAgreement`,
                    headers: {'Content-Type': 'application/json' },
                    params: {
                        UserId:this.$store.getters['userId'] 
                    }
                }).then(()=>{
                    this.$bvModal.hide('DisclaimerDialog');
                    this.$store.commit('updateIsFirstLogin');
                });
         },
          SaveAnalysis(){
              this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/CustomerDashboard`,
                    headers: {'Content-Type': 'application/json' },
                    data: {
                        Id:this.analysisForm.id,
                        CustomerNumber:this.customerNumber,
                        SurveyYear:this.surveyYear,
                        Inflation:this.analysisForm.inflation,
                        GDPAndYoyGrowth:this.analysisForm.gdpAndYoyGrowth,
                        CostOfLiving:this.analysisForm.costOfLiving,
                        UnEmployment:this.analysisForm.unEmployment,
                        InterestRates:this.analysisForm.interestRates,
                        FXUSDoverEGP:this.analysisForm.fxUSDoverEGP,
                        PopulationMin:this.analysisForm.populationMin
                    }
                }).then((response)=>{
                    if(JSON.parse(response.data.status) == true){
                        this.$bvModal.msgBoxOk('Analysis updated successfully', {
                                            title: 'Confirmation',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                            centered: true
                                            });
                    }
                    
                });
          },
          loadReportAnalysis(){
              //jobFamilyMappingChart
                this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerDashboard`,
                params:{
                    CustomerNumber:this.customerNumber,
                    SurveyYear:this.surveyYear
                }
            }).then((response)=>{
                     if(JSON.parse(response.data.status) == true){
                            this.analysisForm.id = response.data.analysisData.id;
                            this.analysisForm.inflation =  response.data.analysisData.inflation;
                            this.analysisForm.gdpAndYoyGrowth =  response.data.analysisData.gdpAndYoyGrowth;
                            this.analysisForm.costOfLiving =  response.data.analysisData.costOfLiving;
                            this.analysisForm.unEmployment =  response.data.analysisData.unEmployment;
                            this.analysisForm.interestRates =  response.data.analysisData.interestRates;
                            this.analysisForm.fxUSDoverEGP =  response.data.analysisData.fxUSDoverEGP;
                            this.analysisForm.populationMin =  response.data.analysisData.populationMin;
                     }
            });
          }
     },
      mounted(){
          this.loadReportAnalysis();
           if(this.$store.getters['isFirstLogin'] == true){
                this.$bvModal.show('DisclaimerDialog');
            }
      }
}
</script>

<style>

</style>