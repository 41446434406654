<template>
     <zingchart :data="jobFamilyMappingChartData" ref="jobFamilyMappingChart"></zingchart>
</template>

<script>
// import axios from 'axios';
import 'zingchart/zingchart-es6.min.js';
import zingchartVue from 'zingchart-vue';
export default {
components: {
        zingchart: zingchartVue,
    },
computed: {
        customerNumber () {
            return this.$store.state.userDetails.subscriptionId;
        },
        surveyYear(){
            return this.$store.getters.surveyYear;
        },
    },
    data(){
        return{
            jobTypes:[
                {text:'EnablerJob', color:'#50ADF5'},
                {text:'CoreJob', color:'#FF7965'},
                {text:'SupportJob', color:'#FFCB45'}
            ],
             jobFamilyMappingChartData: {
                  type: 'pie',
                   plot: {
                        borderColor: "#2B313B",
                        borderWidth: 5,
                        // slice: 90,
                        valueBox: {
                            placement: 'out',
                            text: '%t\n%npv%',
                            fontFamily: "Open Sans"
                        },
                     tooltip: {
                            fontSize: '18',
                            fontFamily: "Open Sans",
                            padding: "5 10",
                            text: "%npv%"
                        },
                    animation: {
                            effect: 2,
                            method: 5,
                            speed: 900,
                            sequence: 1,
                            delay: 3000
                        }
                    },
                    source: {
                        text: 'gs.statcounter.com',
                        fontColor: "#8e99a9",
                        fontFamily: "Open Sans"
                    },
                    title: {
                            fontColor: "#8e99a9",
                            text: 'Job Family Mapping   ',
                            align: "left",
                            offsetX: 10,
                            fontFamily: "Open Sans",
                            fontSize: 25
                        },
                    //  subtitle: {
                    //         offsetX: 10,
                    //         offsetY: 10,
                    //         fontColor: "#8e99a9",
                    //         fontFamily: "Open Sans",
                    //         fontSize: "16",
                    //         text: 'May 2016',
                    //         align: "left"
                    //     },
                    plotarea: {
                            margin: "20 0 0 0"
                        },
                },
        }
    },
     methods:{
         loadReportData(){
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerDashboard/JobFamilyChart`,
                params:{
                    CustomerNumber:this.customerNumber,
                    SurveyYear:this.surveyYear
                }
            }).then((response)=>{
                     if(JSON.parse(response.data.status) == true){
                        // console.log('aaaaaaaaaaaaaaaaaaa')
                        // console.log(response.data);
                        let series = [];
                        this.jobTypes.forEach(element => {
                            let positoinsInType =
                            response.data.analysisData.filter(d=>d.positionType == element.text);
                            let jobTypePercent = positoinsInType.length /  response.data.analysisData.length
                            series.push({
                                values: [jobTypePercent],
                                text: element.text,
                                backgroundColor:element.color,
                            });
                        });
                        console.log('series.............................')
                        console.log(series)
                        this.$refs.jobFamilyMappingChart.series = series;

                     }
            });
         }
     },
     mounted(){
          this.loadReportData();
      }
}
</script>

<style>

</style>